<template>
  <div class="order">
    <header>
      <div class="header h5MaxWidth clear display alignCenter">
        <div
          class="back display alignCenter justifyCenter"
          onclick="window.history.go(-1)"
        >
          <i class="iconfont icon-arrow-left"></i>
        </div>
        <h2 class="flex1">Recharge Record</h2>
        <div class="navskip" id="sideMenu"></div>
      </div>
    </header>
    <div class="box" v-for="(item, index) in dataList" :key="index">
      <div class="top">Order number:{{ item.orderSn }}</div>
      <div class="num">
        <div class="left">
          <div class="lo">
            Amount
          </div>
          <div class="lt">
            State
          </div>
          <div class="lth">
            Date
          </div>
        </div>
        <div class="right">
          <div class="ro">
            {{ item.amount }}
          </div>
          <div class="rt" v-if="item.status == 0" style="flex-wrap: nowrap">
            Processing
          </div>
          <div class="rt" v-if="item.status == 1">
            Successed
          </div>
          <div class="rt" v-if="item.status == 2">
            Failed
          </div>
          <div class="rth">
            {{ item.createTime }}
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {
      dataList: [],
    };
  },
  components: {},
  methods: {},
  mounted() {
    this.getAjax(
      "api/recharge/list",
      {
        uid: this.getItem("uid"),
      },
      "post"
    ).then((res) => {
      this.dataList = res.data;
    });
  },
};
</script>

<style scoped lang="less">
.box {
  background-color: #fff;
  box-sizing: border-box;
  border-bottom-right-radius: 8px;
  margin: 5px 12px;
}
.box .top {
  margin-top: 15px;
  text-align: center;
  font-size: 14px;
  color: #fff;
  background: #dd052b;
  height: 40px;
  line-height: 40px;
}
.box .num {
  margin-top: 10px;
  display: flex;
}
.box .num .left {
  color: #000;
  width: 30%;
  margin-left: 25px;
  line-height: 35px;
  font-size: 14px;
}
.box .num .right {
  width: 70%;
  line-height: 35px;
}
.box .num .right .ro {
  color: #d8a418;
  margin-bottom: 5px;
  font-size: 14px;
  font-weight: 700;
}
.box .num .right .rt {
  width: 100px;
  height: 25px;
  background: #dd052b;
  border-radius: 8px;
  line-height: 25px;
  text-align: center;
  color: #f2f6fc;
  margin-bottom: 5px;
  font-size: 14px;
}
.box .num .right .rth {
  color: #55b432;
  font-size: 14px;
}
</style>
